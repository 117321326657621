const React = require('react');

const SecondaryBanner = require('@mshops-components-library/secondary-banner').default;

const { useRenderContext } = require('../../pages/home/context');

const BannerContainer = (props) => {
  const { deviceType } = useRenderContext();

  return <SecondaryBanner {...props} deviceType={deviceType} />;
};

module.exports = BannerContainer;
