const React = require('react');
const PropTypes = require('prop-types');

const { ItemDesktop } = require('@mshops-components-library/item');
const metadataPropType = require('../../components/metadataProvider/metadataPropType');

function CarouselWrapper(RecommendationsBase) {
  function CarouselBase(props, context) {
    const { metadata } = context;
    const { items, categories } = props;
    const shippingTitle = props?.i18n?.gettext('Envío gratis');

    return Boolean(items?.length || categories?.length) && (
      <div className="mshops-recommendations-wrapper">
        <RecommendationsBase
          metadata={metadata.currency}
          restClient={metadata.restClient}
          type="recommendations"
          {...props}
          shippingTitle={shippingTitle}
        />
      </div>
    );
  }

  CarouselBase.propTypes = {
    i18n: PropTypes.shape({ gettext: PropTypes.func.isRequired }),
    items: PropTypes.arrayOf(PropTypes.shape(ItemDesktop.itemPropTypes)).isRequired,
    title: PropTypes.string,
  };

  CarouselBase.defaultProps = {
    title: '',
  };

  CarouselBase.contextTypes = {
    metadata: metadataPropType.isRequired,
  };

  return CarouselBase;
}

module.exports = CarouselWrapper;
